import { NavBarContext } from "@/pages/_app";
import React, { createContext, useContext } from "react";
import { AccordianItem } from "./Utils/Accordian";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const SidebarContext: any = createContext("");

export default function Sidebar({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const [isNavbarActive, setIsNavbarActive] = useContext(NavBarContext);
  return (
    <>
      <aside className="h-screen ">
        <div
          className={`${
            isNavbarActive
              ? "bg-none absolute top-4 right-5 cursor-pointer hover:opacity-75 hover:scale-110 transition-transform"
              : "hidden"
          }`}
          onClick={() => setIsNavbarActive && setIsNavbarActive(false)}
        >
          <FontAwesomeIcon fontSize={20} className="" icon={faClose} />
        </div>
        <nav className="h-full  flex flex-col bg-white border-r shadow-sm">
          <h4
            className={`text-center text-lg pb-8 pt-5 ${
              !isNavbarActive && "hidden"
            }`}
          >
            {title}
          </h4>

          <SidebarContext.Provider value={{ isNavbarActive }}>
            <ul className="flex-1 px-3">{children}</ul>
          </SidebarContext.Provider>
        </nav>
      </aside>
    </>
  );
}

export function SidebarItem({ icon, text, active, url, children }: any) {
  const { isNavbarActive }: any = useContext(SidebarContext);
  return (
    <AccordianItem
      value={text}
      active={active}
      isNavberActive={isNavbarActive}
      trigger={
        <Link
          href={children ? "#" : url}
          className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group `}
        >
          <div className={`${!isNavbarActive && "scale-125"}`}>{icon}</div>
          <span
            className={`overflow-hidden duration-200 transition-all ${
              isNavbarActive ? " ml-3" : "slide-out"
            }`}
          >
            {text}
          </span>
          {/* {alert && (
            <div
              className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
                isNavbarActive ? "" : "top-2"
              }`}
            ></div>
          )} */}

          {!isNavbarActive && (
            <div
              className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
            >
              {text}
            </div>
          )}
        </Link>
      }
      className={""}
    >
      {children &&
        children.map((navItemChild: any) => (
          <Link
            href={navItemChild.url}
            className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer text-[#78828a] transition-colors group `}
          >
            {navItemChild.icon}
            <span
              className={`overflow-hidden duration-200 transition-all ${
                isNavbarActive ? "w-52 ml-3" : "slide-out"
              }`}
            >
              {navItemChild.label}
            </span>
            {/* {alert && (
              <div
                className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
                  isNavbarActive ? "" : "top-2"
                }`}
              ></div>
            )} */}

            {!isNavbarActive && (
              <div
                className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
              >
                {navItemChild.label}
              </div>
            )}
          </Link>
        ))}
    </AccordianItem>
  );
}
