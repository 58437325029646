import React, { useContext, useState } from "react";
import { Drawer } from "antd";
import Link from "next/link";
import { FiMenu } from "react-icons/fi";
import AuthContext from "@/context/AuthContext";
import { usePathname } from "next/navigation";
import AccountNavigation from "../Navigations/AccountNavigation";

const Sidebar = () => {
  const pathname = usePathname();

  let { user, profile, logoutUser } = useContext(AuthContext);

  const AccountPaths = [
    `/account/${user?.user_id}`,
    `/account/${user?.user_id}/wishlist`,
    `/account/${user?.user_id}/orders`,
  ];

  const showAccountsNaviagtion = AccountPaths.includes(pathname);

  const [ navItems, ] = useState([
    ["Home", "/"],
    // ["Categories"],
    ["All Products", "/all-products"],
    // ["Hot Offers", "/hot-offers"],
    ["Top Sellers", "/top-sellers"],

    ...((!profile?.store) ? [["Create Store", "/store/stores/create"]] : [])
  ]);

  const [loggedInprofileItems] = useState([
    {
      label: "Account",
      icon: "/icons/user.svg",
      url: `/account/${user?.user_id}`,
    },
    {
      label: "Messenger",
      icon: "/icons/messenger.svg",
      uniqueName: "messenger",
      url: user ? `/chat` : "#",
      func: false,
    },
  ]);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="sm:flex md:flex items-center hidden">
      <FiMenu
        className={` ease-out delay-500`}
        style={{
          height: "25px",
          width: "25px",
          fill: "white",
        }}
        onClick={showDrawer}
      />
      <Drawer
        width="60%"
        style={{
          backgroundColor: "#FFFFFF",
          color: "white",
          display: "flex",
          padding: 0,
        }}
        placement="right"
        onClose={onClose}
        open={open}
      >
        {showAccountsNaviagtion ? (
          <div className="flex flex-col justify-between h-full">
            <AccountNavigation />
          </div>
        ) : (
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col">
              {navItems.map((item, index) =>
                !item[1] ? (
                  <>
                    <h4
                      onClick={onClose}
                      className={`text-sm py-2 text-grey-400 font-[400] font-poppins cursor-pointer p-[10px_16px_10px_16px] border-b-[1px]`}
                      key={index}
                    >
                      {item[0]}
                    </h4>
                  </>
                ) : (
                  <>
                    <Link
                      onClick={onClose}
                      className={`text-sm py-2 text-grey-400 font-poppins font-[400] p-[10px_16px_10px_16px] border-b-[1px]`}
                      key={index}
                      href={item[1]}
                      children={item[0]}
                    />
                  </>
                )
              )}
              {user && (
                <>
                  {loggedInprofileItems.map((item, index) => (
                    <Link
                      href={item.url}
                      onClick={onClose}
                      key={index}
                      className="text-sm py-2 text-grey-400 font-[400] font-poppins cursor-pointer p-[10px_16px_10px_16px] border-b-[1px]"
                    >
                      <h4 className="font-[400] font-poppins text-[14px]">
                        {item.label}
                      </h4>
                    </Link>
                  ))}
                </>
              )}
            </div>
            {!user && (
              <div className="flex w-full gap-[12px]">
                <Link
                  className="w-1/2"
                  href={"/account/login"}
                  onClick={onClose}
                >
                  <button
                    className={`font-montserrat text-[14px] w-full items-center justify-center bg-[#E25822] cursor-pointer text-[#FFFFFF] sm:rounded-[4px] md:rounded-[4px] text-center font-[500] hover:opacity-75 active:opacity-75 flex gap-2 p-[8px_16px_8px_16px]`}
                  >
                    Login
                  </button>
                </Link>
                <Link
                  className="w-1/2"
                  href={"/account/register"}
                  onClick={onClose}
                >
                  <button
                    className={`font-montserrat text-[14px] w-full items-center flex justify-center bg-white border border-[#E25822] cursor-pointer text-[#E25822] sm:rounded-[4px] md:rounded-[4px] text-center font-[500] hover:opacity-75 active:opacity-75 p-[8px_16px_8px_16px]`}
                  >
                    Register
                  </button>
                </Link>
              </div>
            )}

            {user && (
              <button
                onClick={logoutUser}
                className={`font-montserrat text-[14px] w-full items-center justify-center bg-[#E25822] cursor-pointer text-[#FFFFFF] sm:rounded-[4px] md:rounded-[4px] text-center font-[500] hover:opacity-75 active:opacity-75 flex gap-2 p-[8px_16px_8px_16px]`}
              >
                Logout
              </button>
            )}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Sidebar;
