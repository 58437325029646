import { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Sidebar from "../Layouts/Sidebar";
import AuthContext from "@/context/AuthContext";
import { useCart } from "@/context/CartContext";
import { Dropdown, MenuProps } from "antd";
import { Categories } from "@/interfaces/account/Home/Categories";
import { URLS } from "@/utils/urls";
import { FiSearch } from "react-icons/fi";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Product } from "@/interfaces/account/store/ProductDetail";
import LoadingDataSpinner from "../LoadingDataSpinner";

interface Props {}

interface SearchProductsProps {
  count: number;
  next: string | null;
  previous: string | null;
  facets: any;
  results: Product[];
}

const HomeNavigation: NextPage<Props> = ({}) => {
  const router = useRouter();
  const { q } = router.query;
  let { user, profile, logoutUser } = useContext(AuthContext);
  const [, setCategoryItems] = useState<any>(undefined);
  const [, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState<any>("");
  const [mobileSearchParam, setMobileSearchParam] = useState<string>("");
  const { cartItemCount } = useCart();

  useEffect(() => {
    if (router.pathname !== "/search-products") {
      setSearchParam("");
      return;
    }
    if (!q) return;
    setSearchParam(q);
  }, [q, router.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("cartItemCount: ", cartItemCount);

  const profileRef = useRef(null);
  const openProfileRef = useRef(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchProducts, setSearchProducts] =
    useState<SearchProductsProps | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const navItems = [
    ["Home", "/"],
    // ["Categories"],
    ["All Products", "/all-products"],
    // ["Hot Offers", "/hot-offers"],
    ["Top Sellers", "/top-sellers"],
  ];

  const [loggedInprofileItems] = useState([
    {
      label: "Account",
      icon: "/icons/user.svg",
      url: user ? `/account/${user?.user_id}` : "#",
    },
    {
      label: "Messenger",
      icon: "/icons/messenger.svg",
      uniqueName: "messenger",
      url: user ? `/chat` : "#",
      func: false,
    },
    {
      label: "Logout",
      icon: "/icons/logout.svg",
      uniqueName: "logout",
      func: true,
    },
  ]);

  const [loggedOutprofileItems] = useState([
    {
      label: "Register",
      icon: "/icons/messenger.svg",
      link: "/account/register",
      uniqueName: "register",
      func: false,
    },
    {
      label: "Login",
      icon: "/icons/logout.svg",
      link: "/account/login",
      uniqueName: "login",
      func: false,
    },
  ]);

  useEffect(() => {
    const handleProfileModal = () => {
      const profileRefElement = profileRef.current as unknown as HTMLDivElement;
      profileRefElement?.addEventListener("mouseleave", () => {
        setIsProfileModalOpen(false);
      });
    };

    handleProfileModal();
  }, [isProfileModalOpen]);

  const handleProfileClick = () => {
    setIsProfileModalOpen((_) => !_);
  };

  const organizeCategoryData = (apiData: Categories): MenuProps["items"] => {
    const organizedData: { [key: string]: string[] } = {};

    apiData.results.forEach((item) => {
      const categoryName = item.category.name;

      if (!organizedData[categoryName]) {
        organizedData[categoryName] = [];
      }

      // organizedData[categoryName].push(item.name);
      organizedData[categoryName]!.push(item.name);
    });

    return Object.entries(organizedData).map(
      ([category, items], categoryIndex) => ({
        key: `${categoryIndex + 1}`,
        label: category,
        // type: "group",
        children: items.map((item, itemIndex) => ({
          key: `${categoryIndex + 1}-${itemIndex + 1}`,
          label: item,
        })),
      })
    );
  };

  useEffect(() => {
    fetchCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(URLS?.categoriesUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCategoryItems(organizeCategoryData(data));
        console.log(organizeCategoryData(data));
      } else {
        console.error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSearchProducts = async (url: string) => {
    setIsSearching(true);
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSearchProducts(data);
      } else {
        console.error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearch = (e: FormEvent<HTMLInputElement>) => {
    const searchURL = `${URLS?.searchProducts}?search=${e.currentTarget.value}`;
    fetchSearchProducts(searchURL);
  };

  const activateSearch = () => {
    setIsSearchActive(true);
  };

  const deactivateSearch = () => {
    setIsSearchActive(false);
  };

  const handleMobileSearch = (product: Product) => {
    router.push(`/search-products?q=${product.slug}`);
    deactivateSearch();
    // setMobileSearchParam("");
  };

  return (
    <div className="relative">
      <div className="flex py-5 px-[82px] sm:px-[14px] md:px-[25px] justify-between items-center">
        <div className="flex">
          <Sidebar />
          <Link href="/">
            <Image
              height={64}
              width={125}
              src={"/icons/logo.png"}
              alt={"HotBuy Mart"}
              className="cursor-pointer hover:opacity-75"
            />
          </Link>
        </div>
        <div className="sm:hidden md:hidden">
          <form action="/search-products">
            <input
              type="text"
              name="q"
              id="q"
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              className="px-5 py-2 text-sm w-[438px] rounded-full bg-[#F7F7F7] outline-none focus:outline focus:outline-offset-0 focus:outline-1 focus:outline-[#E25822]"
              placeholder="Search..."
            />
          </form>
        </div>
        <div className="flex gap-[26px] items-center ">
          <div className="relative">
            <Image
              height={24}
              width={24}
              src={"/icons/user.svg"}
              ref={openProfileRef}
              onClick={handleProfileClick}
              className="cursor-pointer hover:opacity-75"
              alt="User"
            />
            {isProfileModalOpen && (
              <div
                ref={profileRef}
                className={`absolute shadow-[0px_4px_4px_0px_#00000040] right-2 transition-[height] delay-150 ease-in-out duration-500 h-0 ${
                  isProfileModalOpen && "h-[200px] transform"
                } overflow-hidden flex flex-col p-[20px_10px_20px_10px] bg-[#fff] top-8 z-[200] rounded-[4px] w-[200px]`}
              >
                {user && (
                  <>
                    {loggedInprofileItems.map((item, index) =>
                      item.func ? (
                        <button
                          onClick={
                            item?.uniqueName === "logout"
                              ? () => logoutUser()
                              : () => {}
                            // setIsProfileModalOpen(false);
                          }
                          key={index}
                          className="flex gap-[8px] cursor-pointer hover:opacity-80 py-2 border-b border-b-[#00000040]"
                        >
                          <Image
                            height={24}
                            width={24}
                            alt=""
                            src={item.icon}
                          />
                          <h4 className="font-[400] font-poppins text-[14px]">
                            {item.label}
                          </h4>
                        </button>
                      ) : (
                        <Link
                          onClick={() => setIsProfileModalOpen(false)}
                          href={item.url || "#"}
                          key={index}
                          className="flex gap-[8px] cursor-pointer hover:opacity-80 py-2 border-b border-b-[#00000040]"
                        >
                          <Image
                            height={24}
                            width={24}
                            alt=""
                            src={item.icon}
                          />
                          <h4 className="font-[400] font-poppins text-[14px]">
                            {item.label}
                          </h4>
                        </Link>
                      )
                    )}
                  </>
                )}

                {!user && (
                  <>
                    {loggedOutprofileItems.map((item, index) => (
                      <Link
                        onClick={() => setIsProfileModalOpen(false)}
                        href={item.link ? item.link : ""}
                        key={index}
                        className="flex gap-[8px] cursor-pointer hover:opacity-80 py-2 border-b border-b-[#00000040] "
                      >
                        <Image height={24} width={24} alt="" src={item.icon} />
                        <h4 className=" font-[400] font-poppins text-[14px] ">
                          {item.label}
                        </h4>
                      </Link>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>

          <Link href="/basket">
            <div className="relative cursor-pointer">
              <Image
                height={24}
                width={24}
                src="/icons/cart.svg"
                className="hover:opacity-75"
                alt="Cart"
              />
              {cartItemCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                  {cartItemCount}
                </span>
              )}
            </div>
          </Link>
          <div
            onClick={activateSearch}
            className="cursor-pointer hidden sm:block md:block"
          >
            <FiSearch className="text-3xl text-black opacity-70" />
          </div>
        </div>
      </div>
      <div className="bg-[#252525] flex items-center justify-center sm:hidden md:hidden p-2">
        <div className="flex gap-4">
          {navItems.map((item, index) =>
            !item[1] ? (
              <Dropdown
                placement="bottom"
                dropdownRender={() => (
                  // <div className="bg-white rounded-[4px] shadow-[0px_0px_3px_1px_#00000033] p-[30px] gap-[20px] max-w-max flex">
                  //   {categoryItems?.map((item: any) => (
                  //     <div key={item.key} className="flex flex-col gap-2">
                  //       <h4 className="text-left text-[20px] font-montserrat font-[600] select-none">
                  //         {item?.label}
                  //       </h4>
                  //       {item.children.map((child: any) => (
                  //         <div
                  //           className="text-left leading-snug text-[14px] cursor-pointer hover:text-orange font-montserrat"
                  //           key={child.key}
                  //         >
                  //           {child.label}
                  //         </div>
                  //       ))}
                  //     </div>
                  //   ))}
                  // </div>
                  <div></div>
                )}
              >
                <h4
                  className={`text-sm py-2 text-[#ABADB0] font-poppins font-medium barlink cursor-pointer`}
                  key={index}
                >
                  {item[0]}
                </h4>
              </Dropdown>
            ) : (
              <>
                <Link
                  className={`text-sm py-2 text-[#ABADB0] barlink font-poppins font-medium border-[#E25822] ${
                    router.pathname == item[1] &&
                    "text-[#E25822] active border-b-[1.5px]"
                  }`}
                  key={index}
                  href={item[1]}
                  children={item[0]}
                />
              </>
            )
          )}
          {!profile?.store && (
          <Link
              className={`text-sm p-2 border font-poppins font-medium rounded-full border-[#E25822] text-[#E25822] `}
              href={"/store/stores/create"}
              children={"CREATE STORE"}
            />
          )}
        </div>
        <Image
          height={24}
          width={24}
          className="absolute right-[82px] cursor-pointer hover:opacity-75 "
          src={"/icons/scrollIcon.svg"}
          alt="Scroll To"
        />
      </div>
      {isSearchActive && (
        <div className="absolute hidden sm:block md:block inset-0 z-[99999]">
          <div className="fixed inset-0 z-[99999] min-h-screen bg-white">
            <div className="border-b border-b-black border-opacity-40">
              <div className="py-4 px-6 flex items-center justify-evenly gap-3">
                <div onClick={deactivateSearch}>
                  <ArrowBackRoundedIcon className="cursor-pointer scale-100 hover:scale-150 hover:transition-all text-black" />
                </div>
                <form className="flex w-full gap-2" action="/search-products">
                  <input
                    onInput={handleSearch}
                    type="text"
                    name="q"
                    id="q"
                    tabIndex={-1}
                    value={mobileSearchParam}
                    onChange={(e) => setMobileSearchParam(e.target.value)}
                    className="py-2 w-full bg-transparent font-montserrat outline-none"
                    placeholder="Search..."
                  />
                  {/* <button type="submit" className="cursor-pointer">
                    <FiSearch className="text-3xl text-black" />
                  </button> */}
                </form>
              </div>
            </div>
            <div>
              {searchProducts?.results &&
              searchProducts?.results?.length > 0 ? (
                searchProducts.results.map((product, index) => (
                  <div
                    // href={`/store/${product.store.slug}/products/${product.slug}`} Link to the direct Product
                    key={index}
                    onClick={() => handleMobileSearch(product)}
                    className="px-4 py-2 cursor-pointer"
                  >
                    <h4 className="font-montserrat px-4 hover:text-orange active:text-orange">
                      {product.title}
                    </h4>
                  </div>
                ))
              ) : searchProducts?.results.length == 0 && isSearching ? (
                <div className="flex justify-center">
                  <LoadingDataSpinner />
                </div>
              ) : (
                <h4 className="py-4 font-montserrat text-center">No product</h4>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeNavigation;
