import { NextPage } from "next";
import Link from "next/link";
import { useContext } from "react";
import { usePathname } from "next/navigation";
import AuthContext from "@/context/AuthContext";

interface Props {}

const AccountNavigation: NextPage<Props> = ({}) => {
  const pathname = usePathname();
  let { user, profile } = useContext(AuthContext);

  const accountNavigationItems = [
    {
      label: "My Profile",
      url: `/account/${user?.user_id}`,
    },
    ...(profile?.store ? [
      {
        label: "Store Dashboard", // `${profile?.store?.name}`,
        url: `/dashboard/`,
      }
    ] : []),
    {
      label: "Wishlist",
      url: `/account/${user?.user_id}/wishlist`,
    },
    {
      label: "Orders",
      url: `/account/${user?.user_id}/orders`,
    },
    ...(profile?.store ? [{
      label: "Dashboard",
      url: `/dashboard`,
    }] : []),
  ];
  return (
    <div className="flex flex-col py-[20px] gap-[16px] ">
      {accountNavigationItems.map((accountNavigationItem, index) => (
        <Link
          className={` ${
            pathname == accountNavigationItem.url
              ? "text-orange"
              : "text-black opacity-50 font-light"
          } whitespace-nowrap hover:text-orange hover:opacity-90 font-[400] font-poppins text-[16px]`}
          href={accountNavigationItem.url}
          children={accountNavigationItem.label}
          key={index}
        />
      ))}
    </div>
  );
};

export default AccountNavigation;
