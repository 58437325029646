import { NextPage } from "next";
import {
  faAngleDown,
  faBars,
  faBell,
  faCog,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import { useContext, useEffect, useRef, useState } from "react";
import { NavBarContext } from "@/pages/_app";
import AuthContext from "@/context/AuthContext";
import Link from "next/link";
import NotificaionModal from "./NotificaionModal";

interface Props {}

const NavBar: NextPage<Props> = () => {
  const [, setIsNavbarActive] = useContext(NavBarContext) as [
    boolean,
    (value: boolean | ((prev: boolean) => boolean)) => void
  ];
  const bellRef = useRef(null);
  const ordersRef = useRef(null);
  const profileRef = useRef(null);
  const openProfileRef = useRef(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isOrdersModalOpen, setIsOrdersModalOpen] = useState(false);
  const { user, profile, logoutUser } = useContext(AuthContext);

  console.log(profile);

  useEffect(() => {
    const handleOrdersModal = () => {
      const ordersRefElement = ordersRef.current as unknown as HTMLDivElement;
      ordersRefElement?.addEventListener("mouseleave", () => {
        setIsOrdersModalOpen(false);
      });
    };

    const handleProfileModal = () => {
      const profileRefElement = profileRef.current as unknown as HTMLDivElement;
      profileRefElement?.addEventListener("mouseleave", () => {
        setIsProfileModalOpen(false);
      });
    };

    handleOrdersModal();
    handleProfileModal();
  }, [isProfileModalOpen, isOrdersModalOpen]);

  const handleArrowClick = () => {
    setIsProfileModalOpen((_) => !_);
  };

  const handleBarClick = () => {
    setIsNavbarActive((prev) => !prev);
  };

  return (
    <div className="flex w-full py-[8px] px-[31px] justify-between items-center h-[70px] bg-primaryBg">
      <div>
        <FontAwesomeIcon
          onClick={handleBarClick}
          fontWeight={900}
          fontSize={18}
          color="white"
          cursor={"pointer"}
          fontFamily="Font Awesome 6 Free"
          icon={faBars}
        />
      </div>
      <div className="flex w-full justify-end gap-[30px]">
        <div className="relative flex items-center cursor-pointer ">
          <div
            onClick={() => setIsOrdersModalOpen((_) => !_)}
            ref={bellRef}
            className="flex relative items-center cursor-pointer"
          >
            <FontAwesomeIcon
              className="z-10 hover:opacity-80"
              fill=""
              fontSize={20}
              icon={faBell}
            />
            <span className="flex items-center hover:opacity-80 justify-center absolute -top-[14px] -right-[20px] text-white text-[12px] text-center w-[27.5px] h-[22px] bg-[#ee0808] rounded-full">
              0
            </span>
          </div>
          {isOrdersModalOpen && <NotificaionModal ordersRef={ordersRef} />}
        </div>
        <div className="relative">
          <div className="flex items-center gap-2">
            <Image
              src="/icons/avatar-1.png"
              alt={""}
              width={30}
              height={30}
              className="rounded-full"
            />
            <div className="flex items-center cursor-pointer text-white gap-1">
              <h4 className="text-[14px] text-white block">
                Hi, {profile?.first_name}
              </h4>
              <FontAwesomeIcon
                ref={openProfileRef}
                onClick={handleArrowClick}
                className="outline-none text-white cursor-pointer"
                icon={faAngleDown}
              />
            </div>
          </div>
          {isProfileModalOpen && (
            <div className="absolute top-2 right-2">
              <div
                ref={profileRef}
                className="absolute flex flex-col p-[10px] bg-[#fff] top-8 -right-2 z-[100] rounded-[4px] h-min w-[200px] "
              >
                <Link
                  href={`/account/${user?.user_id}`}
                  className="flex p-[10px] hover:opacity-75 cursor-pointer gap-3 text-[13px]  items-center "
                >
                  <FontAwesomeIcon icon={faUser} />
                  <h4>Profile</h4>
                </Link>
                <Link
                  href={"/dashboard/settings/store-update"}
                  className="flex p-[10px] hover:opacity-75 cursor-pointer gap-3 text-[13px]  items-center "
                >
                  <FontAwesomeIcon icon={faCog} />
                  <h4>Settings</h4>
                </Link>
                <div className="flex p-[15px] gap-3 text-[#FC5448] hover:opacity-75  text-[13px] cursor-pointer items-center ">
                  <FontAwesomeIcon icon={faSignOut} />
                  {user ? (
                    <h4 onClick={logoutUser}>Logout</h4>
                  ) : (
                    <Link href="/account/login">Login</Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
