import AuthContext from "@/context/AuthContext";
import { StoreNotification } from "@/interfaces/dashboard/notifications/Notification";
import { formatDateString } from "@/utils/formatDate";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingDataSpinner from "./LoadingDataSpinner";
import { URLS } from "@/utils/urls";

const NotificaionModal = ({ ordersRef }: any) => {
  const notificationsUrl = URLS?.notificationsUrl;
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<StoreNotification[]>([]);
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access;
  const observer = useRef<IntersectionObserver | null>(null);
  const [nextUrl, setNextUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchNotifications(notificationsUrl);
  }, [notificationsUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchNotifications = async (url: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Notifications: ", data);
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...data.results,
        ]);
        setNextUrl(data.next);
      } else {
        console.error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const lastNotificationRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && nextUrl) {
          fetchNotifications(nextUrl);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, nextUrl]
  );

  return (
    <div
      ref={ordersRef}
      className="absolute min-w-[250px] max-w-[350px] flex-col  bg-white shadow-md top-8 sm:top-11 md:top-11 sm:-right-40 md:-right-24 -right-2 z-[1050] rounded-[4px] h-[408px] overflow-y-auto opacity-100"
    >
      <div className="flex justify-between p-[15px]">
        <h4 className="text-[13px]">Notification</h4>
        <h4 className="text-[13px] text-primaryBg">Mark All As Read</h4>
      </div>

      {notifications?.length === 0 && !isLoading ? (
        <div className="flex items-center justify-center h-full">
          <h2>No Products</h2>
        </div>
      ) : (
        <>
          <div>
            {notifications?.map((item, index) => {
              if (notifications.length === index + 1) {
                return (
                  <div
                    ref={lastNotificationRef}
                    key={index}
                    className="flex flex-col p-4 space-y-4  max-w-md"
                  >
                    <div className="items-center space-x-4 rounded-md">
                      <div className="flex flex-col">
                        <span className="font-semibold text-gray-800 hbm-body-nunito-s-bold">
                          {item?.verb}
                        </span>
                        <span className="text-yellow-500 hbm-body-nunito-s">
                          {formatDateString(item?.date_created)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className="flex flex-col p-4 space-y-4  max-w-md"
                  >
                    <div className="items-center space-x-4 rounded-md">
                      <div className="flex flex-col">
                        <span className="font-semibold text-gray-800 hbm-body-nunito-s-bold">
                          {item?.verb}
                        </span>
                        <span className="text-yellow-500 hbm-body-nunito-s">
                          {formatDateString(item?.date_created)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {isLoading && (
            <div className="flex justify-center mt-4">
              <LoadingDataSpinner spinnerHeight="h-8" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotificaionModal;
