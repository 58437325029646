import React from 'react'

interface LoadingDataSpinnerProps {
    spinnerHeight?: string;
    borderColor?: string;
}

const LoadingDataSpinner: React.FC<LoadingDataSpinnerProps> = ({ spinnerHeight, borderColor }) => {
    return (
        <div className={`flex items-center justify-center ${spinnerHeight ? `${spinnerHeight}` : "h-32"}`}>
            <div className={`animate-spin rounded-full h-4 w-4 border-t-2 border-l-2 border-b-2 ${borderColor ? `border-${borderColor}` : "border-primaryBg"} `}></div>
        </div>
    )
}

export default LoadingDataSpinner;