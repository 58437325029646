import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const formatDate = (isoString: string): string => {
  const date = new Date(isoString);
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime).toLowerCase();
  return `${formattedDate}, ${formattedTime}`;
};

export function formatDateString(dateString: string): string {
  const timeZone = 'Asia/Kolkata';
  
  // Parse the ISO date string
  const date = parseISO(dateString);

  // Convert the date to the specified time zone
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, 'MMMM d, yyyy, h:mm aaaa');
}

// This is formatting just the month and year for the income statement feature
export const formatMonthYear = (dateString: string) => {
  const [month, year] = dateString.split('-');
  const monthNames = {
    '1': 'January', '2': 'February', '3': 'March', '4': 'April',
    '5': 'May', '6': 'June', '7': 'July', '8': 'August',
    '9': 'September', '10': 'October', '11': 'November', '12': 'December'
  };
  return `${monthNames[month as keyof typeof monthNames]} ${year}`;
};
