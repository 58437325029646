import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import DownIcon from "@/public/icons/ArrowDown.svg";
import Image from "next/image";

export const AccordianContext = createContext<any | string>("");

interface AccordianProptypes {
  children?: React.ReactNode;
  value?: string;
  onChange?: (_: string) => void;
}

interface AccordianItemPropTypes {
  children?: React.ReactNode;
  ref?: any;
  value: string;
  trigger: any;
  className: string;
  active?: boolean;
  isNavberActive?: boolean;
}

export default function Accordian({
  children,
  value,
  onChange,
  ...props
}: AccordianProptypes) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    onChange?.(selected || "");
  }, [selected]);

  return (
    <ul {...props}>
      <AccordianContext.Provider value={{ selected, setSelected }}>
        {children}
      </AccordianContext.Provider>
    </ul>
  );
}

export function AccordianItem({
  children,
  className,
  value,
  trigger,
  ref,
  isNavberActive,
  active,
  ...props
}: AccordianItemPropTypes) {
  const { selected, setSelected } = useContext(AccordianContext);
  const open = selected === value;

  const ItemRef = useRef<any>(null);

  return (
    <li className={className} ref={ref} {...props}>
      <header
        role="button"
        onClick={() => setSelected(open ? null : value)}
        className={`flex justify-between  items-center font-medium rounded-md ${
          active
            ? "bg-gradient-to-tr from-[#f8fafb73] to-[#f8fafb] text-primaryBg "
            : "hover:bg-[#f8fafb] text-[#78828a] "
        }`}
      >
        <div>{trigger}</div>
        {children && (
          <Image
            src={DownIcon.src}
            alt={""}
            height={24}
            width={24}
            className={`transition-transform ${open ? "rotate-180" : ""} ${
              !isNavberActive && "hidden"
            }`}
          />
        )}
      </header>
      <div
        className={`overflow-y-hidden duration-500 transition-all ${
          !isNavberActive && "hidden"
        }`}
        style={{ height: open ? ItemRef.current?.offsetHeight || 0 : 0 }}
      >
        <div className="" ref={ItemRef}>
          {children}
        </div>
      </div>
    </li>
  );
}
