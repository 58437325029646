import { NextPage } from "next";
import { useContext } from "react";
import Image from "next/image";
import Link from "next/link";
import AuthContext from "@/context/AuthContext";
interface Props { }

const Footer: NextPage<Props> = ({ }) => {
  const { user } = useContext(AuthContext);
  // console.log("user, test deployment")

  return (
    <div className="bg-[#252525] flex flex-wrap mt-20 py-14 sm:px-6 md:px-12 px-20 sm:gap-10 md:gap-14 gap-20">
      <div className="flex flex-col gap-3">
        <Image height={75} width={125} src="/icons/logo.png" alt="HotBuyMart" />
        <div className="sm:flex flex-col gap-1 md:flex">
          <h4 className="text-[12px] text-[#777777] font-semibold">
            Call us: <Link href='tel:+6586164711'>+6586164711</Link>
          </h4>
          <h4 className="text-[12px] text-[#777777] font-[400]">
            Email: <Link href='mailto:sales@hotbuymart.com'>sales@hotbuymart.com</Link>
          </h4>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div>
          <Link className="text-[18px] font-[400] text-[#777777]" href={`/account/${user?.user_id}`}>My Account</Link>
          <hr className="border-[#777777] w-[150px] sm:w-full" />
        </div>
        <div className="flex flex-col gap-1">
          <Link
            className="text-[12px] font-[400] text-[#777777]"
            href={user?.user_id ? `/account/${user?.user_id}/orders` : '/accounts/login'}>
            Order History
          </Link>
          <Link className="text-[12px] font-[400] text-[#777777]" 
            href={user?.user_id ? `/account/${user?.user_id}/whishlist` : '/accounts/login'}>
            Wish List
          </Link>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div>
          <Link className="text-[18px] font-[400] text-[#777777]" href='/about-us'>About Us</Link>
          <hr className="border-[#777777] w-[150px] sm:w-full" />
        </div>
        <div className="flex flex-col gap-1">
          <Link className="text-[12px] font-[400] text-[#777777]" href='/privacy-policy'>
            Privacy & Policy
          </Link>
          <Link className="text-[12px] font-[400] text-[#777777]" href='/terms-condition'>
            Terms & Condition
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <Link className="text-[18px] font-[400] text-[#777777]" href='/privacy-policy'>Privacy & Policy</Link>
          <hr className="border-[#777777] w-[150px] sm:w-full" />
        </div>
        <div className="flex flex-col gap-1">
          <Link className="text-[12px] font-[400] text-[#777777]" href='/about-us'>
            About Us
          </Link>
          <Link className="text-[12px] font-[400] text-[#777777]" href='/contact-us'>
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
